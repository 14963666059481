import * as bootstrap from 'bootstrap'

function formSubmit(event) {
  event.preventDefault()

  var xhr = new XMLHttpRequest();
  var formData = new FormData(event.target);
  var url = "/message";

  xhr.open('POST', url, true);
  xhr.setRequestHeader("Content-Type", "application/json");

  xhr.send(JSON.stringify(Object.fromEntries(formData)));
  xhr.onreadystatechange = function () {
    if (xhr.readyState == XMLHttpRequest.DONE) {
      event.target.reset(); //reset form after AJAX success or do something else
    }
  }
  //Fail the onsubmit to avoid page refresh.
  return false;
}

// and you can attach form submit event like this for example
document.getElementById('form').addEventListener("submit", formSubmit);


// const navLinks = document.querySelectorAll('.nav-item')
// const menuToggle = document.getElementById('navbarNav')
// const bsCollapse = new bootstrap.Collapse(menuToggle)
// navLinks.forEach((l) => {
//   l.addEventListener('click', () => {
//     bsCollapse.toggle()
//   })
// })
